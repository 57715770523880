import { CurryTranslationModel } from '@spices/curry'

export default {
  allowApiSend: {
    source: 'allow_api_send',
    type: Boolean
  },
  assetUrl: {
    source: 'asset_url',
    type: String
  },
  attributes: {
    source: 'attributes',
    type: Array
  },
  chain: {
    source: 'chain',
    type: String
  },
  cid: {
    source: 'cid',
    type: String
  },
  claimable: {
    source: 'claimable',
    type: Boolean
  },
  claimableImage: {
    source: 'claimable_image',
    type: String
  },
  claimableAttributesMode: {
    source: 'claimable_attributes_mode',
    type: String
  },
  claimableAttributesRaw: {
    source: 'claimable_attributes',
    type: Array
  },
  claimableSupply: {
    default: null,
    source: 'claimable_supply',
    type: Number
  },
  created: {
    source: 'created',
    type: Date
  },
  currentDifferentOwners: {
    source: 'current_different_owners',
    type: Number
  },
  currentOwnersCount: {
    source: 'current_owners_count',
    type: Number
  },
  customerId: {
    source: 'customer_id',
    type: String
  },
  description: {
    source: 'description',
    type: String
  },
  embedId: {
    source: 'embed_id',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  initialMintingAmount: {
    default: null,
    source: 'initial_minting_amount',
    type: Number
  },
  lastMintDate: {
    source: 'last_mint_date',
    type: String
  },
  lastSyncDate: {
    format: value => {
      return value ? new Date(value) : null;
    },
    source: 'last_sync_date',
    type: Date
  },
  maxSupply: {
    default: null,
    source: 'max_supply',
    type: Number
  },
  messagingSeed: {
    source: 'messaging_seed',
    type: String
  },
  messagingTopicId: {
    source: 'messaging_topic_id',
    type: String
  },
  metadata: {
    source: 'metadata',
    type: Array
  },
  mintedSupply: {
    source: 'minted_supply',
    type: Number
  },
  modified: {
    source: 'modified',
    type: Date
  },
  name: {
    source: 'name',
    type: String
  },
  nftArtistId: {
    source: 'nft_artist_id',
    type: String
  },
  optimizedImageUrl: {
    source: 'optimized_image_url',
    type: String
  },
  projectId: {
    source: 'project_id',
    type: String
  },
  reference: {
    source: 'reference',
    type: String
  },
  reservedSerials: {
    source: 'reserved_serials',
    type: Array
  },
  royalties: {
    source: 'royalties',
    type: Array
  },
  saylAppBrand: {
    source: 'sayl_app_brand',
    type: Object
  },
  saylNftId: {
    source: 'sayl_nft_id',
    type: String
  },
  status: {
    source: 'status',
    type: Number
  },
  supplyDetails: {
    source: 'supply_details',
    type: String
  },
  switchRatio: {
    source: 'switch_ratio',
    type: Number
  },
  symbol: {
    source: 'symbol',
    type: String
  },
  syncHeight: {
    source: 'sync_height',
    type: Number
  },
  tokenId: {
    source: 'token_id',
    type: String
  },
  totalDifferentOwners: {
    source: 'total_different_owners',
    type: Number
  },
  totalOwnersCount: {
    source: 'total_owners_count',
    type: Number
  },
  totalTokensCount: {
    source: 'total_tokens_count',
    type: Number
  },
  treasuryAccountId: {
    source: 'treasury_account_id',
    type: String
  },
  treasuryWalletAddress: {
    source: 'treasury_wallet_address',
    type: String
  },
  type: {
    source: 'type',
    type: String
  }
}
